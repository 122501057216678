<template>
  <section class="compatibles">
    <div class="container">
      <span>Compatibility with your tools from:</span>
      <h3 v-for="(logo, i) in tools" :key="i">{{ logo }}</h3>
    </div>
  </section>
</template>

<script>
export default {
  name: 'TheCompatibles',
  data: () => ({
    tools: [
      'Optitex',
      'Browzwear',
      'Tukatech',
      'Gerber',
      'Alvanon',
      'CLO3D',
    ],
  }),
}
</script>

<style lang="scss">
.home-page {
  .compatibles {
    .container {
      padding:  25px 80px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;

      border-top: 1px solid var(--Dividers);
      background: var(--Background);

      color: var(--Text-Text_Secondary, #6F8789);
      font-family: Montserrat;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -0.18px;

      span {
        display: block;
        width: 100%;
        margin-bottom: 20px;
      }

      h3 {
        color: var(--Text-Text_Primary);
        font-family: Montserrat;
        font-size: 42px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -1.26px;
      }
    }
  }
}

@media (max-width: 480px) {
  .home-page {
    .compatibles {
      .container {
        padding:  40px 14px;
        gap: 20px;
        justify-content: flex-start;

        span {
          max-width: 100%;
        }

        h3 {
          font-size: 24px;
        }
      }
    }
  }
}
</style>
